import { useGetAllMyChatDetails } from "../../hooks";
import { ConversationQuickDetails } from "../../conversation/quickDetails";
import { ChevronDown, ChevronUp, Folder, FolderPen } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { ParentFolderDetails } from "../types";
import { sortConversation } from "../../../streamChat/utils";
import invariant from "tiny-invariant";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { SubFolder } from "./subfolder";
import { ActionMenu } from "./actionMenu";
import { CreateNewSubFolder } from "../createNewSubFolder";

type ConversationFolderProps = {
  folder: ParentFolderDetails;
  isFirst: boolean;
  isLast: boolean;
  onMoveUp: () => void;
  onMoveDown: () => void;
};

export const ConversationFolder = ({
  folder,
  isFirst,
  isLast,
  onMoveUp,
  onMoveDown,
}: ConversationFolderProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data } = useGetAllMyChatDetails(true);
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const allChannelIds = [
    ...folder.channelIds,
    ...folder.subFolders.map((sf) => sf.channelIds).flat(),
  ];

  const allFolderAndSubFolderConversations = data!.conversations.filter((c) =>
    allChannelIds.includes(c.channelId!)
  );

  const folderConversations = data!.conversations.filter((c) =>
    folder.channelIds.includes(c.channelId!)
  );

  const unreadMessageCount = allFolderAndSubFolderConversations.filter(
    (c) => c.numberOfUnreadMessages > 0
  ).length;

  useEffect(() => {
    if (folder.channelIds.length == 0 && folder.subFolders.length == 0) {
      setIsExpanded(false);
    }
  }, [folder.channelIds, folder.subFolders]);

  useEffect(() => {
    const folderEl = ref.current;
    invariant(folderEl); // Ensure the column element exists

    // Set up the drop target for the column element
    return dropTargetForElements({
      element: folderEl,
      onDragStart: () => setIsDraggedOver(true),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      getData: () => ({ type: "folder", folderId: folder.id }),
      getIsSticky: () => false,
    });
  }, [folder.id]);

  const isFolderCompletelyEmpty =
    folder.channelIds.length + folder.subFolders.length == 0;

  return (
    <div
      className="-ml-6 relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering ? (
        <div className="absolute top-0 left-0 space-y-2 pt-1">
          <div
            className={`w-4 h-4 opacity-50 ${
              isFirst
                ? "cursor-not-allowed"
                : "cursor-pointer hover:opacity-100"
            }`}
            onClick={() => {
              if (isFirst) return;
              onMoveUp();
            }}
          >
            <ChevronUp className="w-4 h-4" />
          </div>

          <div
            className={`w-4 h-4 opacity-50 ${
              isLast ? "cursor-not-allowed" : "cursor-pointer hover:opacity-100"
            }`}
            onClick={() => {
              if (isLast) return;
              onMoveDown();
            }}
          >
            <ChevronDown className="w-4 h-4" />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        className={`border p-2 rounded-lg cursor-pointer ml-6 ${
          isDraggedOver ? "bg-blue-50" : ""
        }`}
        ref={ref}
        onClick={() => {
          if (isFolderCompletelyEmpty) return;

          setIsExpanded(!isExpanded);
        }}
      >
        <div className="w-full flex items-center justify-between">
          <div className="w-full flex items-center space-x-2">
            <div className="w-4 h-4">
              {unreadMessageCount > 0 ? (
                <FolderPen className="w-4 h-4" />
              ) : (
                <Folder className="w-4 h-4" />
              )}
            </div>

            <ActionMenu
              folderId={folder.id}
              currentName={folder.name}
              isExpanded={isExpanded}
            />

            {unreadMessageCount > 0 ? (
              <div className="bg-ipractice-blue px-1 py-px rounded-lg text-white flex items-center justify-center text-xs">
                {unreadMessageCount}
              </div>
            ) : (
              <></>
            )}
          </div>

          {isFolderCompletelyEmpty ? (
            <></>
          ) : (
            <div className="w-4 h-4 opacity-80 hover:opacity-100">
              {isExpanded ? (
                <ChevronUp className="w-4 h-4" />
              ) : (
                <ChevronDown className="w-4 h-4" />
              )}
            </div>
          )}
        </div>

        {isExpanded ? (
          <div>
            <div className="space-y-1 mt-1">
              {folder.subFolders.map((sf) => {
                return (
                  <SubFolder
                    key={sf.id}
                    parentFolder={folder}
                    subFolder={sf}
                    conversations={allFolderAndSubFolderConversations.filter(
                      (c) => sf.channelIds.includes(c.channelId!)
                    )}
                  />
                );
              })}
            </div>

            <div className="mt-2">
              <CreateNewSubFolder
                parentFolder={folder}
                conversations={data!.conversations}
              />
            </div>

            <div className="px-2 mt-2 bg-slate-50 rounded divide-y">
              {folderConversations.toSorted(sortConversation).map((c) => {
                return (
                  <ConversationQuickDetails
                    key={c.channelId}
                    conversation={c}
                    parentFolderId={folder.id}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <>
            {isFolderCompletelyEmpty ? (
              <div>
                <p className="pl-6 text-xs opacity-50">
                  Empty folder, drag a conversation here
                </p>
                <div className="pl-6 mt-1">
                  <CreateNewSubFolder
                    parentFolder={folder}
                    conversations={data!.conversations}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};
