import { Configuration, PopupRequest, LogLevel } from "@azure/msal-browser";

const isAcceptanceEnvironment = window.location.host.includes("ipractice-test") ||
                                window.location.host.includes("localhost");

const commonConfig = {
  auth: {
    clientId: 'f83b7b09-26ba-4498-8ddf-b2979c01908c',
    redirectUri: `${window.location.protocol}//${window.location.host}/redirect`,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level : LogLevel, message : string, containsPii : boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Warning,
    },
  },
};

// TODO: Separate the build pipelines for production and acceptance environments
export const msalConfig: Configuration = {
  ...commonConfig,
  auth: {
    ...commonConfig.auth,
    authority: isAcceptanceEnvironment
      ? 'https://ipracticeacceptance.b2clogin.com/ipracticeacceptance.onmicrosoft.com/b2c_1_SUSI'
      : 'https://ipracticeacceptance.b2clogin.com/ipracticeproduction.onmicrosoft.com/b2c_1_SUSI',
    knownAuthorities: [isAcceptanceEnvironment ? 'ipracticeacceptance.b2clogin.com' : 'ipracticeproduction.b2clogin.com'],
    clientId: isAcceptanceEnvironment ? 'f83b7b09-26ba-4498-8ddf-b2979c01908c' : 'f83b7b09-26ba-4498-8ddf-b2979c01908c',
  },
};

export const loginRequest = {
  scopes: ["https://ipracticeacceptance.onmicrosoft.com/backendapi/user_access"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};