import { DefaultGenerics, StreamChat } from "stream-chat";
import { Channel, Chat, StreamMessage, SendButton } from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
import ChannelInner from "../../streamChat/components/channelInner";
import MessagingThreadHeader from "../../streamChat/components/MessagingThreadHeader";
import { TypingIndicator } from "../../streamChat/components/TypingIndicator";
import { StreamChatGenerics } from "../../streamChat/types";
import { useGetChatDetails } from "../../streamChat/hooks";
import { useIsEnabled } from "../../../feature-management/useIsEnabled";
import { customReactionOptions } from "../../streamChat/customReactions";

type CaseloadChatProps = {
  dossierLookupId: string;
  chatClient: StreamChat<DefaultGenerics> | null;
  couldNotLoadChat: boolean;
  defaultMessage: StreamMessage<StreamChatGenerics> | undefined;
};

export const CaseloadChat = ({
  dossierLookupId,
  chatClient,
  couldNotLoadChat,
  defaultMessage,
}: CaseloadChatProps) => {
  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  const {
    data: chatDetails,
    isLoading,
    isFetching,
  } = useGetChatDetails(isCaseLoadManagerWireReplacementEnabled ?? false, [
    dossierLookupId,
  ]);

  const getChannelId = () => {
    if (chatDetails == null || chatDetails?.length === 0) return null;

    return chatDetails[0].channelId ?? null;
  };

  if (!chatClient || (isLoading && getChannelId() == null))
    return <div>Loading...</div>;

  if (getChannelId() == null) {
    return (
      <div className="w-full py-12 flex items-center justify-center">
        <div className="text-center">
          <p className="text-xl">
            It looks like there isn't a chat setup for the client.
          </p>
          <p>Please make sure this is NOT a client who is still using Wire.</p>
        </div>
      </div>
    );
  }

  if (couldNotLoadChat) {
    return (
      <div className="w-full py-12 flex items-center justify-center">
        <div className="text-center">
          <p className="text-xl">The chat could not be loaded.</p>
          <p>Please make sure this is NOT a client who is still using Wire.</p>
        </div>
      </div>
    );
  }

  const channel = chatClient.channel("messaging", getChannelId());

  return (
    <div className="min-w-full h-[55vh]">
      <Chat client={chatClient}>
        <Channel
          reactionOptions={customReactionOptions}
          channel={channel}
          maxNumberOfFiles={10}
          multipleUploads={true}
          SendButton={SendButton}
          ThreadHeader={MessagingThreadHeader}
          TypingIndicator={TypingIndicator}
          enrichURLForPreview
          channelQueryOptions={{
            watch: true,
          }}
        >
          <ChannelInner
            chatDetails={chatDetails![0]}
            isFetchingChatDetails={isFetching}
            toggleMobile={() => {}}
            defaultMessage={defaultMessage}
          />
        </Channel>
      </Chat>
    </div>
  );
};
