import { useGetAllMyChatDetails } from "../hooks";
import { ConversationQuickDetails } from "../conversation/quickDetails";
import { sortConversation } from "../../streamChat/utils";
import { Input } from "../../../components/ui/input";
import { useCallback, useEffect, useState } from "react";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
  DragLocationHistory,
  DropTargetRecord,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import { CreateNewFolder } from "../conversationFolders/createNewFolder";
import { Folders } from "../conversationFolders/folders";
import {
  useAddConversationToFolder,
  useAddConversationToSubFolder,
  useRemoveConversationFromFolder,
  useRemoveConversationFromSubFolder,
} from "../conversationFolders/hooks";

export const Sidebar = () => {
  const { data } = useGetAllMyChatDetails(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAllClients, setFilteredAllClients] = useState(
    (data?.conversations ?? []).toSorted(sortConversation)
  );

  const { mutate: addConversationToFolder } = useAddConversationToFolder();
  const { mutate: addConversationToSubFolder } =
    useAddConversationToSubFolder();

  const { mutate: removeConversationFromFolder } =
    useRemoveConversationFromFolder();

  const { mutate: removeConversationFromSubFolder } =
    useRemoveConversationFromSubFolder();

  const handleDrop = useCallback(
    ({ location }: { location: DragLocationHistory }) => {
      const draggedChannels = location.initial.dropTargets.filter(
        (dt) => dt.data.type === "conversation"
      );
      if (draggedChannels.length != 1) {
        return; // this should never happen
      }

      const draggedChannel = draggedChannels[0];

      if (location.current.dropTargets.length == 0) {
        handleConversationRemoval(draggedChannel); // dropped outside the list
      } else {
        handleConversationToFolderOrSubFolderDrop(draggedChannel, location);
      }
    },
    []
  );

  const handleConversationRemoval = (draggedChannel: DropTargetRecord) => {
    if (draggedChannel.data.parentSubFolderId != null) {
      removeConversationFromSubFolder({
        subFolderId: draggedChannel.data.parentSubFolderId as string,
        folderId: draggedChannel.data.parentFolderId as string,
        channelId: draggedChannel.data.conversationChannelId as string,
      });
    } else if (draggedChannel.data.parentFolderId != null) {
      removeConversationFromFolder({
        folderId: draggedChannel.data.parentFolderId as string,
        channelId: draggedChannel.data.conversationChannelId as string,
      });
    } else {
      // this should never happen
    }
  };

  const handleConversationToFolderOrSubFolderDrop = (
    draggedChannel: DropTargetRecord,
    location: DragLocationHistory
  ) => {
    const targetFolders = location.current.dropTargets.filter(
      (dt) => dt.data.type == "folder"
    );
    const targetSubFolders = location.current.dropTargets.filter(
      (dt) => dt.data.type == "sub-folder"
    );
    const conversationId = draggedChannel.data.conversationChannelId as string;

    if (targetSubFolders.length > 0) {
      addConversationToSubFolder({
        draggedFromFolderId: draggedChannel.data.parentFolderId as string,
        draggedFromSubFolderId: draggedChannel.data.parentSubFolderId as string,
        folderId: targetFolders[0].data.folderId as string,
        subFolderId: targetSubFolders[0].data.subFolderId as string,
        channelId: conversationId,
      });
    } else if (targetFolders.length > 0) {
      addConversationToFolder({
        draggedFromFolderId: draggedChannel.data.parentFolderId as string,
        draggedFromSubFolderId: draggedChannel.data.parentSubFolderId as string,
        folderId: targetFolders[0].data.folderId as string,
        channelId: conversationId,
      });
    } else {
      // this should never happen
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      setFilteredAllClients(data!.conversations.toSorted(sortConversation));
    } else {
      const matches = data!.conversations.filter(
        (c) =>
          c.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          `${c.epdPatientId}.${c.epdFileId}`.includes(searchTerm.toLowerCase())
      );
      setFilteredAllClients(matches);
    }
  }, [searchTerm]);

  useEffect(() => {
    return monitorForElements({
      onDrop: handleDrop,
    });
  }, [handleDrop]);

  return (
    <div className="w-[30%] p-6 h-[90vh] overflow-y-auto border-r">
      <h1 className="text-xl font-bold">My Chats</h1>

      <div className="mt-6">
        <Folders />
      </div>

      <CreateNewFolder conversations={data?.conversations ?? []} />

      <div className="mt-4 pt-4 border-t">
        <p className="text-xs opacity-50 mb-2">All conversations</p>

        <Input
          placeholder="Search clients... "
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="mt-2 divide-y divide-gray-100">
          {filteredAllClients.length === 0 ? (
            <p className="italic opacity-75 pt-4">No chats found.</p>
          ) : (
            <></>
          )}
          {filteredAllClients.map((c) => (
            <ConversationQuickDetails
              key={c.channelId}
              conversation={c}
              showLastMessage
            />
          ))}
        </div>
      </div>
    </div>
  );
};
