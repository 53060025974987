import { createContext } from "react";

interface ChatContextProps {
  selectedConversationChannelId: string | null;
  setSelectedConversationChannelId: (newValue: string | null) => void;
}

export const ChatContext = createContext<ChatContextProps>({
  selectedConversationChannelId: null,
  setSelectedConversationChannelId: () => {},
});
