import { useSnackbar } from "notistack";
import axios, { AxiosError } from "axios";
import * as HttpStatus from "http-status-codes";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import React from "react";

const AxiosSetup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { instance, accounts } = useMsal();

  // Configure axios defaults
  const setupAxiosDefaults = () => {
    axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/`;
    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
    axios.defaults.withCredentials = true;
  };

  // Initialize active account
  const initializeActiveAccount = () => {
    if (!instance.getActiveAccount() && accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
  };

  // Token request configuration
  const tokenRequest = {
    scopes: ["https://ipracticeacceptance.onmicrosoft.com/backendapi/user_access"],
    account: instance.getActiveAccount() || undefined,
  };

  // Get access token
  const getAccessToken = async (account: AccountInfo | null) => {
    if (!account) {
      throw new Error("No active account found");
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...tokenRequest,
        account,
      });
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interactive method
        const response = await instance.acquireTokenPopup(tokenRequest);
        return response.accessToken;
      }
      throw error;
    }
  };

  // Request interceptor
  const setupRequestInterceptor = () => {
    axios.interceptors.request.use(
      async (config) => {
        try {
          // Skip token acquisition if specified
          if (config.headers['Skip-Authorization']) {
            return config;
          }

          const activeAccount = instance.getActiveAccount();
          const token = await getAccessToken(activeAccount);
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } catch (error) {
          console.error('Token acquisition failed:', error);
          enqueueSnackbar("Authentication failed. Please try again.", { 
            variant: "error",
            autoHideDuration: 3000
          });
          return Promise.reject(error);
        }
      },
      (error) => Promise.reject(error)
    );
  };

  // Response interceptor
  const setupResponseInterceptor = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (!error.response) {
          enqueueSnackbar("Network error. Please check your connection.", {
            variant: "error",
            autoHideDuration: 5000
          });
          return Promise.reject(error);
        }

        const status = error.response.status;
        const errorMessages: Record<number, string> = {
          [HttpStatus.StatusCodes.INTERNAL_SERVER_ERROR]: "Server error occurred",
          [HttpStatus.StatusCodes.UNAUTHORIZED]: "Session expired. Please log in again.",
          [HttpStatus.StatusCodes.FORBIDDEN]: "Access denied",
          [HttpStatus.StatusCodes.REQUEST_TOO_LONG]: "File is too large. Please reduce the size and try again.",
          [HttpStatus.StatusCodes.BAD_REQUEST]: "Invalid request",
          [HttpStatus.StatusCodes.CONFLICT]: "Request conflict detected"
        };

        if (errorMessages[status]) {
          enqueueSnackbar(errorMessages[status], {
            variant: "error",
            autoHideDuration: 5000
          });
        }

        return Promise.reject(error);
      }
    );
  };

  // Initialize everything
  React.useEffect(() => {
    setupAxiosDefaults();
    initializeActiveAccount();
    setupRequestInterceptor();
    setupResponseInterceptor();

    // Optional: Cleanup function
    return () => {
      axios.interceptors.request.eject(0);
      axios.interceptors.response.eject(0);
    };
  }, []);

  return null;
};

export default AxiosSetup;