import { ChatContext } from "../context";
import { useContext, useRef, useState } from "react";
import { Sheet, SheetClose, SheetContent } from "../../../components/ui/sheet";
import { ChatConversationDetails } from "../../streamChat/types";
import { Avatar, Channel, SendButton } from "stream-chat-react";
import ChannelInner from "../../streamChat/components/channelInner";
import MessagingThreadHeader from "../../streamChat/components/MessagingThreadHeader";
import { TypingIndicator } from "../../streamChat/components/TypingIndicator";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { VideoCallButton } from "../../streamChat/video/videocallButton";
import { ParticipantsButton } from "../../streamChat/components/participantsButton";
import { customReactionOptions } from "../../streamChat/customReactions";

type ChatWindowProps = {
  hasAnyConversations: boolean;
  conversation: ChatConversationDetails | null;
  chatClient: StreamChat<DefaultGenerics>;
};

export const ChatWindow = ({
  hasAnyConversations,
  chatClient,
  conversation,
}: ChatWindowProps) => {
  const { selectedConversationChannelId } = useContext(ChatContext);

  if (!hasAnyConversations) {
    return (
      <div className="h-[90vh] bg-slate-100 w-[70%] flex items-center justify-center text-2xl opacity-70">
        At the moment, you don't have any conversations.
      </div>
    );
  }

  if (selectedConversationChannelId == null || conversation == null) {
    return (
      <div className="h-[90vh] bg-slate-100 w-[70%] flex items-center justify-center text-2xl opacity-70">
        Please select a conversation from the sidebar.
      </div>
    );
  }

  const channel = chatClient.channel("messaging", conversation.channelId);

  const CustomAvatar = (props: any) => {
    return <Avatar {...props} image={props.user.avatar} />;
  };

  return (
    <div className="relative w-[70%] h-[90vh]">
      <div className="py-4 px-6 bg-slate-50 flex items-center justify-between border-t border-r border-b">
        <h1 className="text-2xl">
          <span className="font-bold">{conversation.clientName}</span> -{" "}
          {conversation.epdPatientId}.{conversation.epdFileId}
        </h1>
        <div className="flex items-center space-x-2">
          <VideoCallButton
            isFetchingChatDetails={false}
            chatDetails={conversation}
          />
          <ParticipantsButton
            isFetchingChatDetails={false}
            chatDetails={conversation}
          />
        </div>
      </div>

      <Channel
        reactionOptions={customReactionOptions}
        Avatar={CustomAvatar}
        channel={channel}
        maxNumberOfFiles={10}
        multipleUploads={true}
        SendButton={SendButton}
        ThreadHeader={MessagingThreadHeader}
        TypingIndicator={TypingIndicator}
        enrichURLForPreview
        channelQueryOptions={{
          watch: true,
        }}
      >
        <ChannelInner
          chatDetails={conversation}
          isFetchingChatDetails={false}
          toggleMobile={() => {}}
          defaultMessage={undefined}
          hideControls
        />
      </Channel>
    </div>
  );
};
