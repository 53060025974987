import { useEffect, useState } from "react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../../components/ui/hover-card";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useGetAuthorizations } from "../../../../shared/hooks/useGetAuthorizations";

export const OthersViewingReferral = ({
  referralId,
}: {
  referralId: string;
}) => {
  const [usersOnPage, setUsersOnPage] = useState<string[]>([]);
  const { data: authorization } = useGetAuthorizations();

  const connection = new HubConnectionBuilder()
    .withUrl(`${window.location.protocol}//${window.location.host}/crm-hub`)
    .configureLogging(LogLevel.None)
    .build();

  useEffect(() => {
    connection
      .start()
      .then(() => {
        connection.invoke("Join", referralId).catch((e) => {
          /* todo */
        });
      })
      .catch((e) => {
        /* todo */
      });

    return () => {
      if (connection.state !== "Connected") return;

      connection.invoke("Leave", referralId).catch((e) => {
        /* todo */
      });

      connection.stop().catch((e) => {
        /* todo */
      });
    };
  }, []);

  connection.on("Update", (message: string[]) => {
    // filter out the current user
    setUsersOnPage(
      message.filter((user) => user !== authorization?.userName)
    );
  });

  if (usersOnPage.length === 0) {
    return <></>;
  }

  const cutOffUserCount = usersOnPage.length - usersOnPage.slice(0, 5).length;

  return (
    <>
      <p className="text-xs opacity-70">
        There are others viewing or editing this referral:
      </p>
      <div className="pt-2 flex items-center space-x-1">
        {usersOnPage.slice(0, 5).map((user, index) => {
          const variant = (index % 5) as 0 | 1 | 2 | 3 | 4;

          return <Avatar variant={variant} user={user} />;
        })}
        {cutOffUserCount > 0 && (
          <p className="text-xs pl-2">...and {cutOffUserCount} others.</p>
        )}
      </div>
    </>
  );
};

const Avatar = ({
  variant,
  user,
}: {
  variant: 0 | 1 | 2 | 3 | 4;
  user: string;
}) => {
  const getColor = () => {
    const color0 = `bg-orange-100 dark:bg-slate-800 text-orange-400 border-orange-200 dark:text-slate-400 dark:border-none`;
    const color1 = `bg-green-100 dark:bg-slate-800 text-green-400 border-green-200 dark:text-slate-400 dark:border-none`;
    const color2 = `bg-blue-100 dark:bg-slate-800 text-blue-400 border-blue-200 dark:text-slate-400 dark:border-none`;
    const color3 = `bg-fuchsia-100 dark:bg-slate-800 text-fuchsia-600 border-fuchsia-200 dark:text-slate-400 dark:border-none`;
    const color4 = `bg-gray-200 dark:bg-slate-800 text-gray-500 border-gray-400 dark:text-slate-400 dark:border-none`;

    switch (variant) {
      case 0:
        return color0;
      case 1:
        return color1;
      case 2:
        return color2;
      case 3:
        return color3;
      case 4:
        return color4;
      default:
        return "";
    }
  };

  return (
    <HoverCard openDelay={200}>
      <HoverCardTrigger asChild>
        <div className="h-8 w-8">
          <div
            className={`h-8 w-8 rounded-full border ${getColor()} flex items-center justify-center`}
          >
            {user.slice(0, 1).toUpperCase()}
          </div>
        </div>
      </HoverCardTrigger>
      <HoverCardContent
        className="w-fit z-[99999]"
        side="bottom"
        align="center"
      >
        <p>{user}</p>
      </HoverCardContent>
    </HoverCard>
  );
};
