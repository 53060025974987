import {
  DropdownMenuItem,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuSub,
} from "../../../../components/ui/dropdown-menu";
import { Shield, Tags, User } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const UserManagementSubMenu = () => {
  const navigate = useNavigate();

  return (
    <>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger className="cursor-pointer flex justify-between">
          <div className="flex space-x-2 items-center">
            <div className="w-4 h-4">
              <User className="h-4 w-4" />
            </div>
            <p>User Management</p>
          </div>
        </DropdownMenuSubTrigger>

        <DropdownMenuPortal>
          <DropdownMenuSubContent className="z-[99999999]">
            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();

                navigate("/admin/users/roles");
              }}
            >
              <div className="w-4 h-4">
                <Shield className="h-4 w-4" />
              </div>
              <p>Role Assignments, 3rd party IDs</p>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();

                navigate("/admin/users/tags");
              }}
            >
              <div className="w-4 h-4">
                <Tags className="h-4 w-4" />
              </div>
              <p>Tag Administration</p>
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </>
  );
};
