import { NavBarOpenStateContext } from "./NavBarOpenStateContext";
import {
  Calendar,
  FolderOpen,
  Home,
  MessageSquareText,
  NotebookTabs,
  RefreshCcw,
  UserRoundCog,
  UserRoundPlus,
  UsersRound,
  BetweenHorizontalStart,
  MessageCircleQuestion,
  MessageSquareMore,
  List,
} from "lucide-react";
import { useContext, useEffect } from "react";
import { SettingsMenu } from "./SettingsMenu";
import { AdminMenu } from "./adminMenu/AdminMenu";
import { NavBarSeparator } from "./NavBarSeparator";
import { ShowOrHideNavBar } from "./ShowOrHideNavBar";
import { NavItem } from "./NavItem";
import { useIsEnabled } from "../../feature-management/useIsEnabled";
import roleNames from "../../utils/constants/roleNames";
import { ReportingMenu } from "./reportingMenu/ReportingMenu";
import { useGetAuthorizations } from "../../shared/hooks/useGetAuthorizations";
import { useMsal } from "@azure/msal-react";


export const NavBar = () => {
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const { data: authorization } = useGetAuthorizations();

  const { data: isCrmEnabledViaFeatureFlag } = useIsEnabled(
    "EnableCustomerRelationshipManagerApi"
  );

  const { data: isGermanClientManagementEnabled } = useIsEnabled(
    "EnableGermanyClientManagement"
  );

  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  const isConsultationRoomPsychologist =
    authorization?.position == "Consultation Room MSc Psychologist" ||
    authorization?.position == "GZ Psychologist";

  const isCrmEnabled =
    authorization?.roles.includes(roleNames.ADMINISTRATOR) ||
    authorization?.position == "Matching Psychologist" ||
    isCrmEnabledViaFeatureFlag;

    const isReportManager = authorization?.roles.includes(roleNames.REPORT_MANAGER);
    const isAdmin = authorization?.roles.includes(roleNames.ADMINISTRATOR);
    const isOnlinePsychologist = authorization?.roles.includes(roleNames.ONLINE_PSYCHOLOGIST);

    const isChatEnabled = isConsultationRoomPsychologist && isCaseLoadManagerWireReplacementEnabled;

  // Use useEffect to load the external script
  useEffect(() => {
    if (process.env.REACT_APP_HIDE_JIRA_ISSUE_COLLECTOR_SCRIPT === "true")
      return;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://ipractice.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3ddrgv/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=b7e94bbd";
    script.async = true;

    script.onerror = () => {
      // avoid showing a whole-page-error screen if things go wrong
      console.error("Failed to load the Jira issue collector script.");
    };

    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <aside
      className={`${isNavBarOpen ? "pl-6" : "pl-3"} min-h-screen py-6 relative`}
    >
      <div className="flex flex-1 flex-col space-y-12 font-medium sticky top-0">
        <div className={`${isNavBarOpen ? "p-2" : "p-1"} mt-4 h-20`}>
          <h1
            className={`text-3xl text-ipractice-blue font-medium ${
              isNavBarOpen ? "visible" : "hidden"
            }`}
          >
            iPractice
          </h1>
          <p
            className={`uppercase text-xs text-gray-500 tracking-widest ${
              isNavBarOpen ? "visible" : "hidden"
            }`}
          >
            Dashboard
          </p>
        </div>

        <ul className="mt-12 w-full">
          <NavItem icon={<Home className="w-5 h-5" />} text="Home" to="/" />

          {(isAdmin || isOnlinePsychologist) && (
            <>
              <NavItem
                icon={<UserRoundPlus className="w-5 h-5" />}
                text="Cliënt aanmaken"
                to="/create-patient"
              />

              <NavItem
                icon={<UsersRound className="w-5 h-5" />}
                text="Cliënten"
                to="/patients"
              />

              <NavItem
                icon={<UserRoundCog className="w-5 h-5" />}
                text="Onboarding"
                to="/onboarding"
              />

              {isGermanClientManagementEnabled && (
                <NavItem
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="flag-icons-de"
                      viewBox="0 0 640 480"
                      className="w-5 h-5"
                    >
                      <path fill="#fc0" d="M0 320h640v160H0z" />
                      <path fill="#1e293b" d="M0 0h640v160H0z" />
                      <path fill="#f87171" d="M0 160h640v160H0z" />
                    </svg>
                  }
                  text="iPractice Deutschland Kunden"
                  to="/germany/clients"
                />
              )}
              <NavBarSeparator />
            </>
          )}

          {(isAdmin || isOnlinePsychologist) && (
            <>
              <NavItem
                icon={<FolderOpen className="w-5 h-5" />}
                text="Praktijkdata Dossiers"
                to="/dossiers-ggz"
              />

              <NavItem
                icon={<RefreshCcw className="w-5 h-5" />}
                text="Transfer Dossiers"
                to="/dossiers-transfer"
              />
              <NavBarSeparator />
            </>
          )}

          {(isAdmin || isOnlinePsychologist) && (
            <>
              <NavItem
                icon={<Calendar className="w-5 h-5" />}
                text="Kalender"
                to="/calendar"
              />
            </>
          )}

          {isCrmEnabled && (
            <NavItem
              icon={<NotebookTabs className="w-5 h-5" />}
              text="Patiëntenverwijzingen"
              to="/patient-referrals"
            />
          )}
          {(isAdmin || isChatEnabled) && (
            <NavItem
              icon={<MessageSquareMore className="w-5 h-5" />}
              text="My Chats"
              to="/chat"
            />
          )}
          {(isAdmin || isOnlinePsychologist) && (
            <NavItem
              icon={
                isAdmin || isChatEnabled ? (
                  <List className="w-5 h-5" />
                ) : (
                  <MessageSquareText className="w-5 h-5" />
                )
              }
              text="Mijn Caseload"
              to="/case-load"
              disabled={
                activeAccount?.idTokenClaims?.epdId
                  ? undefined
                  : {
                      isDisabled: true,
                      reason:
                        "Your user is not linked to PraktijkData. Please contact IT Support.",
                    }
              }
            />
          )}

          <NavBarSeparator />

          <SettingsMenu />

          {(isReportManager || isAdmin || isOnlinePsychologist) && (
            <ReportingMenu />
          )}

          {isAdmin && <AdminMenu />}
        </ul>
        <ShowOrHideNavBar />
      </div>
    </aside>
  );
};
