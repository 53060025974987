import { useEffect, useState } from "react";
import { Sidebar } from "./sidebar/sidebar";
import { ChatWindow } from "./chatWindow/chatWindow";
import { ChatContext } from "./context";
import { useIsEnabled } from "../../feature-management/useIsEnabled";
import { useGetAuthorizations } from "../../shared/hooks/useGetAuthorizations";
import { sortConversation } from "../streamChat/utils";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { useGetChatCredentials } from "../streamChat/hooks";
import { useGetAllMyChatDetails } from "./hooks";
import { Chat as StreamChatComponent } from "stream-chat-react";
import { Loading } from "./loading";
import useStores from "../../useStores";
import roleNames from "../../utils/constants/roleNames";

export default function Chat() {
  const { data: authorization } = useGetAuthorizations();
    const isAdmin = authorization?.roles.includes(roleNames.ADMINISTRATOR);

  const {
    data: isCaseLoadManagerWireReplacementEnabled,
    isLoading: isLoadingIsCaseLoadManagerWireReplacementEnabled,
  } = useIsEnabled("EnableCaseLoadManagerWireReplacement");

  const isConsultationRoomPsychologist =
    authorization?.position == "Consultation Room MSc Psychologist" ||
    authorization?.position == "GZ Psychologist";

  const isChatEnabled =
    isAdmin ||
    (isConsultationRoomPsychologist &&
      (isCaseLoadManagerWireReplacementEnabled ?? false));

  const { isLoading, isError, data } = useGetAllMyChatDetails(isChatEnabled);
  const [selectedConversationChannelId, setSelectedConversationChannelId] =
    useState<string | null>(null);

  const [couldNotLoadChat, setCouldNotLoadChat] = useState(false);

  const [chatClient, setChatClient] =
    useState<StreamChat<DefaultGenerics> | null>(null);

  const apiKey =
    process.env.REACT_APP_STREAM_CHAT_API_KEY ?? "should-not-happen";

  const { data: chatCredentials } = useGetChatCredentials(
    isCaseLoadManagerWireReplacementEnabled ?? false
  );

  useEffect(() => {
    const initChat = async () => {
      try {
        const client = StreamChat.getInstance(apiKey);

        await client.connectUser(
          { id: chatCredentials!.userId },
          chatCredentials!.token
        );
        setChatClient(client);
      } catch (error) {
        setCouldNotLoadChat(true);
      }
    };

    if (
      isCaseLoadManagerWireReplacementEnabled &&
      !chatClient &&
      chatCredentials?.token != null
    ) {
      initChat();
    }
  }, [chatCredentials, isCaseLoadManagerWireReplacementEnabled]);

  useEffect(() => {
    if (!data || data!.conversations.length == 0) return;

    const firstChannel =
      data!.conversations.toSorted(sortConversation)[0].channelId;

    setSelectedConversationChannelId(firstChannel);
  }, [data]);

  if (isLoading || isLoadingIsCaseLoadManagerWireReplacementEnabled) {
    return <Loading />;
  }

  if (!isChatEnabled) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div>
          <h1 className="text-4xl font-bold">Coming soon!</h1>
          <p className="pt-1">The chat feature is not yet available.</p>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Something went wrong...</div>;
  }

  return (
    <main className="my-12 max-h-[90vh] overflow-y-hidden">
      <ChatContext.Provider
        value={{
          selectedConversationChannelId,
          setSelectedConversationChannelId,
        }}
      >
        <div className="flex items-start">
          <Sidebar />
          {couldNotLoadChat ? (
            <div className="bg-red-50 w-full h-[90vh] flex items-center justify-center text-red-500 border border-red-100 rounded">
              <section className="text-center">
                <h1 className="text-3xl font-bold">Something went wrong</h1>
                <p>
                  Sorry! It looks like we couldn't load the chat. Please try
                  again later, or contact the tech team.
                </p>
              </section>
            </div>
          ) : (
            <>
              {chatClient ? (
                <StreamChatComponent client={chatClient!}>
                  <ChatWindow
                    hasAnyConversations={data!.conversations.length > 0}
                    chatClient={chatClient!}
                    conversation={
                      selectedConversationChannelId
                        ? data!.conversations.filter(
                            (x) => x.channelId == selectedConversationChannelId
                          )[0]
                        : null
                    }
                  />
                </StreamChatComponent>
              ) : (
                <div>Loading...</div>
              )}
            </>
          )}
        </div>
      </ChatContext.Provider>
    </main>
  );
}
