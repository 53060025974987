import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuGroup,
} from "../../../components/ui/dropdown-menu";
import {
  BarChart3,
  BetweenHorizontalStart,
  BookAudio,
  BriefcaseMedical,
  Calendar,
  ChevronRight,
  FileLineChart,
  MessageCircleQuestion,
  Table,
} from "lucide-react";
import { useContext } from "react";
import { NavBarOpenStateContext } from "../NavBarOpenStateContext";
import { useNavigate } from "react-router-dom";
import roleNames from "../../../utils/constants/roleNames";
import { useGetAuthorizations } from "../../../shared/hooks/useGetAuthorizations";
import { useIsEnabled } from "../../../feature-management/useIsEnabled";

export const ReportingMenu = () => {
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;
  const navigate = useNavigate();
  const { data: authorization } = useGetAuthorizations();

  const { data: isCrmEnabledViaFeatureFlag } = useIsEnabled(
    "EnableCustomerRelationshipManagerApi"
  );

  const isReportingEnabled =
    authorization?.roles.includes(roleNames.ADMINISTRATOR) ||
    authorization?.roles.includes(roleNames.REPORT_MANAGER) ||
    authorization?.position == "Matching Psychologist" ||
    isCrmEnabledViaFeatureFlag;

  if (!isReportingEnabled) {
    return <></>;
  }

  return (
    <li className="my-[3px] p-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex items-center justify-between w-full hover:text-blue-500 cursor-pointer">
            <div className="flex items-center space-x-2 w-fit">
              <div className="w-5 h-5">
                <BarChart3 className="w-5 h-5" />
              </div>
              <p className={`${isNavBarOpen ? "visible" : "hidden"}`}>
                Reporting
              </p>
            </div>

            {isNavBarOpen && (
              <div className="w-4 h-4">
                <ChevronRight className="w-4 h-4" />
              </div>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-[24rem] space-y-1"
          style={{ zIndex: 99999 }}
          side="right"
          align="end"
        >
          <DropdownMenuGroup>
            {authorization?.roles.includes(roleNames.ADMINISTRATOR) && (
              <DropdownMenuItem
                className="cursor-pointer space-x-2 flex items-center"
                onClick={(e) => {
                  e.preventDefault();

                  navigate("/admin/reports/akwa-ggz");
                }}
              >
                <div className="w-4 h-4">
                  <Calendar className="h-4 w-4" />
                </div>
                <p>Akwa GGZ</p>
              </DropdownMenuItem>
            )}
            {authorization?.roles.includes(roleNames.ADMINISTRATOR) && (
              <DropdownMenuItem
                className="cursor-pointer space-x-2 flex items-center"
                onClick={(e) => {
                  e.preventDefault();

                  navigate("/admin/reports/big-register");
                }}
              >
                <div className="w-4 h-4">
                  <BriefcaseMedical className="h-4 w-4" />
                </div>
                <p>BIG Registration</p>
              </DropdownMenuItem>
            )}
            {authorization?.roles.includes(roleNames.ADMINISTRATOR) && (
              <DropdownMenuItem
                className="cursor-pointer space-x-2 flex items-center"
                onClick={(e) => {
                  e.preventDefault();

                  navigate("/admin/reports/cohort-study");
                }}
              >
                <div className="w-4 h-4">
                  <BookAudio className="h-4 w-4" />
                </div>
                <p>Cohort Study</p>
              </DropdownMenuItem>
            )}
            {authorization?.roles.includes(roleNames.ADMINISTRATOR) && (
              <>
                <DropdownMenuItem
                  className="cursor-pointer space-x-2 flex items-center"
                  onClick={(e) => {
                    e.preventDefault();

                    navigate("/admin/reports/cqi");
                  }}
                >
                  <div className="w-4 h-4">
                    <Table className="h-4 w-4" />
                  </div>
                  <p>CQi Questionnaire</p>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}

            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();
                navigate("/reports/patient-referrals/statistics");
              }}
            >
              <div className="w-4 h-4">
                <FileLineChart className="h-4 w-4" />
              </div>
              <p>CRM Referral Report</p>
            </DropdownMenuItem>

            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();
                navigate("/reports/empty-intake-spots");
              }}
            >
              <div className="w-4 h-4">
                <BetweenHorizontalStart className="h-4 w-4" />
              </div>
              <p>Empty Intake Spots Report</p>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();
                navigate("/reports/intake");
              }}
            >
              <div className="w-4 h-4">
                <MessageCircleQuestion className="h-4 w-4" />
              </div>
              <p>SKP Intake Report</p>
            </DropdownMenuItem>

            <DropdownMenuItem
              className="cursor-pointer space-x-2 flex items-center"
              onClick={(e) => {
                e.preventDefault();
                navigate("/reports/pre-intake");
              }}
            >
              <div className="w-4 h-4">
                <MessageCircleQuestion className="h-4 w-4" />
              </div>
              <p>OP Pre-Intake Report</p>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};
