import { ReactElement, useContext } from "react";
import { NavBarOpenStateContext } from "./NavBarOpenStateContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { useNavigate, useLocation } from "react-router-dom";

export const NavItem = ({
  text,
  icon,
  to,
  disabled,
}: {
  text: string;
  icon: ReactElement;
  to?: string;
  disabled?: { isDisabled: boolean; reason: string };
}) => {
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;
  const navigate = useNavigate();
  const location = useLocation();
  const selected = to == location.pathname;

  const getSelectedClass = () =>
    selected
      ? `w-full bg-background ${
          isNavBarOpen ? "py-3 pr-3 pl-2" : "p-2"
        } text-blue-500 font-medium`
      : "w-fit p-2";

  const DisasbledNavigationEntry = () => {
    return (
      <li
        className={`w-full flex my-[3px] items-center justify-between rounded-tl-xl rounded-bl-xl ${getSelectedClass()}`}
      >
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger className="block w-full cursor-not-allowed">
              <div className="flex items-center space-x-2 opacity-50">
                <div className="w-5 h-5">{icon}</div>
                <p className={`${isNavBarOpen ? "visible" : "hidden"}`}>
                  {text}
                </p>
              </div>
            </TooltipTrigger>
            <TooltipContent
              style={{ zIndex: 999999999 }}
              className="p-2 bg-black text-gray-50"
            >
              <p className="break-words max-w-[12rem]">{disabled!.reason}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div className="w-4 h-4 mr-2"></div>
      </li>
    );
  };

  const NavigationEntry = () => {
    return (
      <li
        className={`flex my-[3px] items-center rounded-tl-xl rounded-bl-xl space-x-2 hover:text-blue-500 cursor-pointer ${getSelectedClass()}`}
        onClick={() => {
          navigate(to || "");
        }}
      >
        <div className="w-5 h-5">{icon}</div>
        <p className={`${isNavBarOpen ? "visible" : "hidden"}`}>{text}</p>
      </li>
    );
  };

  return isNavBarOpen ? (
    disabled?.isDisabled ? (
      <DisasbledNavigationEntry />
    ) : (
      <NavigationEntry />
    )
  ) : disabled?.isDisabled ? (
    <DisasbledNavigationEntry />
  ) : (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger className="block w-full">
          <NavigationEntry />
        </TooltipTrigger>
        <TooltipContent
          align="center"
          side="right"
          style={{ zIndex: 99999 }}
          className="p-2 bg-black text-gray-50"
        >
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};